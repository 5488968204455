<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <h1>This is an home page</h1>
  </div>
</template>

<script>
export default {
  name: 'home',
  computed: {
  },
  components: {},
  mounted () {
  },
  methods: {
  }
}
</script>
